import React, { useEffect, useState } from 'react';
import * as styles from "./default-product.module.scss";
import GsOption from "../page-components/option/gsOption";
import GsButton from "../page-components/buttons/gsButton";
import cogoToast from "cogo-toast";
import ShoppingCartSVG from "../icons/fontawesome/light/shopping-cart.svg";
import { useStoreContext } from "../provider/storeProvider";
import { gsCurrencyPipe } from "../helper/gsCurrencyPipe";
import { navigate } from "gatsby";
import GsProductCompareAtPrice from "../page-components/product/gsProductCompareAtPrice";

import ChevronDownSVG from "../icons/fontawesome/light/chevron-down.svg";

const DefaultProductConfiguration = ( {
                                          product,
                                          requestContactModal = () => { },
                                          handleAvailableVariants = (variants) => { }
                                       } ) => {

    const storeContext = useStoreContext();

    const [ selectedOptions, setSelectedOptions ] = useState( { } );
    const [ availableOptions, setAvailableOptions ] = useState( { } );
    const [ availableVariants, setAvailableVariants ] = useState([]);

    const [ allOption4, setAllOption4 ] = useState(false);
    const [ allOption5, setAllOption5 ] = useState(false);


    const option4Label = product.metafields.find( m => m.key === "option4Label" );
    let option4 = product.metafields.find( m => m.key === "option4" );


    if (option4) {
        option4 = option4.value.split('\n');
    }

    const option5Label = product.metafields.find( m => m.key === "option5Label" );
    let option5 = product.metafields.find( m => m.key === "option5" );
    if (option5) {
        option5 = option5.value.split('\n');
    }

    let hasNoOptions = product.options.length === 1 && product.options[0].name === 'Title';

    let cantBuy = false;
    if (product.vendor && product.vendor.toLowerCase() === 'ping') {
        cantBuy = true;
    }

    if (product.tags && product.tags.indexOf("no-sale") !== -1) {
        cantBuy = true;
    }

    useEffect(
        () => {
            const availableVariants = product.variants.filter(
                (v) => {
                    for (const [ key, value ] of Object.entries(selectedOptions)) {

                        if (key === option5Label?.value || key === option4Label?.value) {
                            continue;
                        }

                        const option = (v.selectedOptions.find( o => o.name === key));

                        const variantValue = option.value;
                        if (variantValue !== value) {
                            return false;
                        }

                    }

                    return true;
                }
            );

            setAvailableVariants(availableVariants);
            handleAvailableVariants(availableVariants);

            const availableOptions = { };

            for (const a of availableVariants) {
                for (const so of a.selectedOptions) {

                    const value = so.value;
                    const key = so.name;

                    if (availableOptions[key]) {
                        availableOptions[key].push(value);
                    } else {
                        availableOptions[key] = [ value ];
                    }
                }
            }

            setAvailableOptions(availableOptions);

        },
        [ selectedOptions ]
    );

    // initial effect - if there is an option 4 or 5 - then set first value as default
    useEffect(
        () => {

            const initialSettings = { };

            if (option5) {
                initialSettings[option5Label.value] = option5[0];
            }

            if (option4) {
                initialSettings[option4Label.value] = option4[0];
            }

            if (initialSettings !== { }) {
                setSelectedOptions({...selectedOptions, ...initialSettings});
            }

        },
        []
    );


    return <>

        <GsProductCompareAtPrice className={"mt-4"}
                                 preferredVariant={availableVariants.length === 1 ? availableVariants[0] : undefined}
                                 product={product} />

        <p className={"mt-1 " + styles.price}>
            Preis&nbsp;
            { availableVariants.length !== 1 && <>{gsCurrencyPipe(product.priceRangeV2.minVariantPrice.amount)}</> }
            { availableVariants.length === 1 && <>{gsCurrencyPipe(availableVariants[0].price)}</> }
        </p>

        <p className={styles.ustInfo + " mb-1"}>
            Preis inkl. 20% Ust, länderspezifischer Preis wird im Checkout berechnet
        </p>

        {!hasNoOptions &&
        <div className="row mt-4">
            <div className="col-12">
                <div className={styles.optionWrapper}>

                    {product.options.map(
                        (option, i) => {

                            return <div className={"my-2"} key={i}>

                                <strong className={"d-block mb-1"}>{option.name}</strong>

                                {option.values.map(v => {
                                    return <GsOption className={"me-2 c-pointer d-inlineblock bg-white"}
                                                     onClick={ () =>
                                                     {

                                                         // check if disabled
                                                         if (availableOptions && !selectedOptions[option.name] && (!availableOptions[option.name]?.find(a => a == v))) {
                                                             return;
                                                         }

                                                         setSelectedOptions(
                                                             {
                                                                 ...selectedOptions,
                                                                 [option.name]: v
                                                             }
                                                         )
                                                     }
                                                     }
                                                     isDisabled={
                                                         availableOptions && !selectedOptions[option.name] && (!availableOptions[option.name]?.find(a => a == v))
                                                     }
                                                     isChecked={ selectedOptions[option.name] === v }>
                                        {v}
                                    </GsOption>
                                })}

                            </div>

                        })
                    }


                    {option4 && <>
                        <div className={"my-2"} key={"option4"}>
                            <strong className="d-block mb-1">{option4Label.value}</strong>
                            {
                                option4.map(
                                    (o, i) => {
                                        if (!allOption4 && i !== 0) {
                                            return null;
                                        }

                                        return <GsOption    className={"me-2 c-pointer d-inlineblock bg-white"}
                                                            onClick={ () =>
                                                            {
                                                                setSelectedOptions(
                                                                    {
                                                                        ...selectedOptions,
                                                                        [option4Label.value]: o
                                                                    }
                                                                )
                                                            }
                                                            }
                                                            isChecked={ selectedOptions[option4Label.value] === o }>
                                            { o }
                                        </GsOption>
                                    })
                            }

                            {
                                !allOption4 &&
                                    <ChevronDownSVG className={"c-pointer"} onClick={ () => setAllOption4(v => true)} />
                            }

                        </div>
                    </>}

                    {option5 && <>
                        <div className={"my-2"} key={"option5"}>
                            <strong className="d-block mb-1">{option5Label.value}</strong>
                            {
                                option5.map(
                                    (o, i) => {

                                        if (!allOption5 && i !== 0) {
                                            return null;
                                        }

                                        return <GsOption    className={"me-2 c-pointer d-inlineblock bg-white"}
                                                            onClick={ () =>
                                                            {
                                                                setSelectedOptions(
                                                                    {
                                                                        ...selectedOptions,
                                                                        [option5Label.value]: o
                                                                    }
                                                                )
                                                            }
                                                            }
                                                            isChecked={ selectedOptions[option5Label.value] === o }>
                                            { o }

                                        </GsOption>
                                    })
                            }

                            {
                                !allOption5 && <ChevronDownSVG className={"c-pointer"} onClick={ () => setAllOption5(true) } />
                            }

                        </div>
                    </>}

                </div>
            </div>
        </div>
        }

        <GsButton className={
            "w-100 py-4 d-flex justify-content-center align-items-center "
            + styles.buyBtn
        }

                  disabled={ availableVariants.length === 0 }
                  onClick={
                      async () => {

                          if (storeContext.store.adding) {
                              return;
                          }

                          if (availableVariants.length === 0) {
                              cogoToast.warn("Ungültige Kombination ausgewählt");
                              return;
                          }

                          if (availableVariants.length > 1) {
                              cogoToast.warn("Bitte wählen Sie eine vollständige Konfiguration");
                              return;
                          }

                          if (cantBuy) {
                              requestContactModal(selectedOptions);
                          } else {

                              let customAttributes = [];

                              if (selectedOptions[option4Label?.value]) {
                                  customAttributes.push(
                                      { key: option4Label.value, value: selectedOptions[option4Label?.value] }
                                  );
                              }

                              if (selectedOptions[option5Label?.value]) {
                                  customAttributes.push(
                                      { key: option5Label.value, value: selectedOptions[option5Label?.value] }
                                  );
                              }

                              await storeContext.addVariantToCart(
                                  availableVariants[0].shopifyId,
                                  1,
                                  customAttributes
                              );

                              await navigate("/warenkorb");
                          }
                      }
                  }
                  variant={"accent"}>

            <ShoppingCartSVG className={"me-2 svgFill"} />

            { cantBuy && "Produkt anfragen" }
            { !cantBuy && "in den Warenkorb"}
        </GsButton>

        { availableVariants.length === 0 &&
        <p className={"text-center mt-3 text-warn"}>Sie haben eine nicht mögliche Konfiguration ausgewählt</p>
        }

    </>;
};

export default DefaultProductConfiguration;
