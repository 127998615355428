import Modal from "react-modal";
import React, { useEffect, useRef } from "react";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import * as styles from "./gsDefaultModal.module.scss";
import TimesSVG from "../../icons/fontawesome/light/times.svg";

const customStyles = {
    content: {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
        overflow: 'auto',
        borderRadius: "12px",
        padding: '0',
    },

    overlay : {
        zIndex: 19000,
        background: "rgba(0,0,0,.8)"
    }
};

Modal.setAppElement(`#___gatsby`);

const GsDefaultModal = ({ children,
                         isOpen = false,
                         title = '',
                         requestClose = () => { }
                     } ) => {

    const modalRef = useRef();

    useEffect(
        () => {
            if (isOpen && modalRef.current) {
                disableBodyScroll(modalRef.current);
            }
        },
        [ isOpen ]
    );

    return <Modal isOpen={isOpen}
                  ref={modalRef}
                  onAfterClose={ () => {
                      clearAllBodyScrollLocks();
                  } }
                  style={customStyles}>

        <div className={styles.modalContent}>

            <div className={"d-flex p-4 align-items-center justify-content-between " + styles.header}>
                <h3>{title}</h3>

                <TimesSVG className={"c-pointer"} onClick={requestClose} />
            </div>

            { children}
        </div>

    </Modal>



};

export default GsDefaultModal;
