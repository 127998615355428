import React, { useState } from 'react';

import { Formik } from "formik";
import GsDefaultModal from "./gsDefaultModal";
import { getStrapiUrl } from "../../helper/gsConstants";
import * as axios from "axios";

const GsContactModal = ({
                            children,
                            isOpen = false,
                            requestClose = () => { },
                            selectedOptions,
                            product
                        } ) => {

    const [ state, setState ] = useState('initial');

    return <GsDefaultModal
        isOpen={isOpen}
        requestClose={requestClose}
        title={"Kontaktformular"}>

        <div className="mx-5">

        { children }

            {state === 'initial' &&
            <Formik
                initialValues={
                    {
                        email: '',
                        name: '',
                        phone: '',
                        message: ''
                    }
                }
                onSubmit={
                    async (values, {setSubmitting}) => {

                        try {
                            const location = window.location.href;

                            const keys = Object.keys(selectedOptions);
                            let options = "";
                            for (const k of keys) {
                                options += "<b>" + k + "</b>: " + selectedOptions[k] + ", ";
                            }

                            const message = values.message + "<br /><br />" +
                                "<b>Produkt: </b>" + product.title + "<br /><br /><br />" +
                                "<b>Optionen:</b> <br /> " + options
                            ;

                            const url = "https://www.golfsport.at/produkt/" + product.handle;


                            await axios.post(getStrapiUrl() + "/contact", { ...values, message, url });
                            setState('success');
                        } catch (err) {
                            window.alert("Es ist ein unbekannter Fehler beim Senden passiert - bitte probieren Sie es später erneut");
                        } finally {
                            setSubmitting(false);
                        }
                    }
                }>

                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                  }) => (
                    <form className={"form mb-5 mt-3"} onSubmit={handleSubmit}>

                        <div className="form-control">
                            <label htmlFor="email">E-Mail</label>

                            <input type="email"
                                   name="email" id="email"
                                   onChange={handleChange}
                                   onBlur={handleBlur}/>
                        </div>

                        <div className="form-control">
                            <label htmlFor="phone">Telefon</label>

                            <input type="tel"
                                   name="phone" id="phone"
                                   onChange={handleChange}
                                   onBlur={handleBlur}/>
                        </div>

                        <div className="form-control">
                            <label htmlFor="name">Name</label>

                            <input type="text"
                                   name="name" id="name"
                                   onChange={handleChange}
                                   onBlur={handleBlur}/>
                        </div>

                        <div className="form-control">
                            <label htmlFor="message">Nachricht</label>

                            <textarea
                                rows="10"
                                name="message" id="message"
                                onChange={handleChange}
                                onBlur={handleBlur}>
                        </textarea>
                        </div>

                        <div className="form-control text-center">
                            <button className={"mt-1 submitBtn"}>Jetzt abschicken</button>
                        </div>

                    </form>
                )}


            </Formik>
            }

            { state === 'success' &&
            <h4 className={"mt-2 mb-5"}>Ihre Anfrage wurde erfolgreich abgesendet.</h4>
            }

        </div>





    </GsDefaultModal>

};

export default GsContactModal;
